import React, { Component } from 'react';
import { TextInputBase } from 'react-native';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import Input from 'src/components/input';
import APIService from 'src/libs/api-service';
import apiService from 'src/libs/api-service';
import slugify from 'src/libs/slugify';
import { keyframes, styled } from 'src/styled/components';

class Play extends Component<any, any> {
  private tipInterval: any;
  private tipTimeout: any;
  private pling: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      songs: [],
      tip: '',
      tipFadeOut: false,
      tips: [],
    };
  }

  public async componentDidMount() {
    this.setState({ loading: true });
    const songs = await APIService.getSongs();
    const tips = await APIService.getTips();
    this.pling = new Audio('/tam.mp3');
    this.setState({ loading: false, songs, tips });

    this.tipInterval = setInterval(this._getRandomTip.bind(this), 30 * 1000);
    // this._getRandomTip();
  }

  public componentWillUnmount() {
    clearInterval(this.tipInterval);
    clearTimeout(this.tipTimeout);
  }

  public render() {
    const { loading, songs, tip, tipFadeOut } = this.state;

    if (loading) {
      return <div>Loading</div>;
    }

    let song = songs.filter((item: any) => {
      return item.youtubeId === this.props.match.params.youtubeId;
    });

    song = song[0];

    return (
      (song && (
        <Main>
          {loading && <div>Loading...</div>}
          {!loading && <Title>{song.title || 'Loading ...'}</Title>}
          {!loading && (
            <div>
              <FrameWrap>
                <YouTubeFrame
                  id="player"
                  opts={{
                    playerVars: {
                      modestbranding: 1,
                      start: song.startTime || 0,
                    },
                  }}
                  onReady={this._onPlayerReady}
                  videoId={song.youtubeId}
                />
              </FrameWrap>
              {tip && (
                <Tip className={tipFadeOut ? 'fade-out' : ''}>
                  <TipContent>{tip}</TipContent>
                </Tip>
              )}
            </div>
          )}
        </Main>
      )) ||
      'asdf'
    );
  }

  public _onPlayerReady(ev: any) {
    ev.target.playVideo();
  }

  private _getRandomTip() {
    const { tips } = this.state;

    if (tips.length === 0) {
      return;
    }

    const rnd = (max: number) => {
      return Math.floor(Math.random() * Math.floor(max));
    };

    const random = rnd(tips.length - 1);

    const tip = tips.splice(random, 1)[0].text;

    if (tip) {
      this.pling.currentTime = 0;
      this.pling.play();

      this.setState({
        tip,
        tipFadeOut: false,
      });

      this.tipTimeout = setTimeout(() => {
        setTimeout(() => {
          this.setState({
            tip: '',
          });
        }, 1 * 1000);

        this.setState({
          tipFadeOut: true,
        });
      }, 19 * 1000);
    }
  }
}

const Main = styled.main`
  background-color: black;
  height: 100vh;
`;

const Title = styled.h1`
  padding: 0 0.5em;
  margin: 0 0 1em;
`;

const marquee = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-50%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Tip = styled.div`
  font-family: 'Courier New', sans-serif;
  font-weight: bold;
  font-size: 2.5em;
  line-height: 2.8125rem;
  background-color: white;
  opacity: 1;
  animation: ${fadeIn} 1s ease;
  transition: opacity 1s ease;

  &.fade-out {
    opacity: 0;
  }

  &:before {
    display: block;
    background-image: linear-gradient(to right, #7cff00, #ff004c, #000dff);
    height: 10px;
    width: 100%;
    content: '';
    margin-bottom: 35px;
  }

  &:after {
    display: block;
    background-image: linear-gradient(to right, #7cff00, #ff004c, #000dff);
    height: 10px;
    width: 100%;
    content: '';
    margin-top: 35px;
  }
`;

const TipContent = styled.div`
  animation: ${marquee} 10s linear 2;
  transform: translateX(100%);
`;

const YouTubeFrame = styled(YouTube)`
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
`;

const FrameWrap = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media screen and (min-width: 1600px) {
    height: 600px;
    width: 1066.666666667px;
    padding-bottom: 0;
  }
`;

const SongLink = styled(Link)`
  color: green;
`;

export default Play;
