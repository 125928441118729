import ResponseError from 'src/errors/response';

export default {
  async getSongs(): Promise<any> {
    return this._castResponse<any>('/songs.json');
  },

  async getTips(): Promise<any> {
    return this._castResponse<any>('/tips.json');
  },

  // async generatePlaylist(q: any): Promise<PlaylistResponse> {
  //   return this._castPostResponse<PlaylistResponse>('/playlist', {}, q);
  // },

  // async getPlaylist(id: number): Promise<PlaylistResponse> {
  //   return this._castResponse<PlaylistResponse>(`/playlist/${id}`);
  // },

  _castResponse<T>(path: string, params: any = {}): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this._getJson(path, params);

        resolve((response as any) as T);
      } catch (error) {
        reject(error);
      }
    });
  },

  _castPostResponse<T>(path: string, params: any = {}, body: string | null = null): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this._postJson(path, params, body);

        resolve((response as any) as T);
      } catch (error) {
        reject(error);
      }
    });
  },

  async _request(method: string, path: string, params: any = {}, body: any = null): Promise<Response> {
    const query = Object.keys(params)
      .map(k => `${k}=${encodeURIComponent(params[k])}`)
      .join('&');

    const url = `${process.env.REACT_APP_BACKEND_URL}${path}${query ? `?${query}` : ''}`;

    const headers: any = {
      'Content-Type': 'application/json',
    };

    // Apply Authorization header if we have a token
    const token = this._getToken();

    if (token !== false) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (params.bypassCache === true) {
      delete params.bypassCache;
      headers['Cache-Control'] = 'max-age=0';
    }

    return fetch(url, {
      body: body ? JSON.stringify(body) : null,
      headers,
      method,
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        throw new ResponseError(response.status, response.statusText);
      }

      return response;
    });
  },

  async _get(path: string, params: any = {}): Promise<Response> {
    return this._request('GET', path, params);
  },

  async _getJson(path: string, params: any = {}): Promise<Response> {
    return this._get(path, params).then(response => response.json());
  },

  async _post(path: string, params: any = {}, body: string | null = null): Promise<Response> {
    return this._request('POST', path, params, body);
  },

  _postJson(path: string, params: any = {}, body: string | null = null): Promise<Response> {
    return this._post(path, params, body).then(response => response.json());
  },

  _getToken(): boolean | string {
    const token: string | null = localStorage.getItem('loginSession');
    return token === null ? false : JSON.parse(token).access_token;
  },
};
