import * as styledComponents from 'styled-components';

import ThemeInterface from 'src/interfaces/theme-interface';
import mq from './media-queries';
import themes from './themes';

const { default: styled, css, createGlobalStyle, keyframes, ThemeProvider } = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { styled, css, createGlobalStyle, keyframes, ThemeProvider, mq, themes };
export default styled;
