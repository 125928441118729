import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/home';
import Play from './containers/play';

export default () => (
  <Switch>
    <Route path="/" exact={true} component={Home} />
    <Route path="/play/:youtubeId" component={Play} />
  </Switch>
);
