import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Input from 'src/components/input';
import APIService from 'src/libs/api-service';
import slugify from 'src/libs/slugify';
import { styled } from 'src/styled/components';

interface IState {
  loading: boolean;
  songs: any[];
}

class Home extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      songs: [],
    };
  }

  public async componentDidMount() {
    this.setState({ loading: true });
    const songs = await APIService.getSongs();
    this.setState({ loading: false, songs });
  }

  public render() {
    const { loading, songs } = this.state;
    return (
      <Main>
        <Line />
        <Hero>
          <HeroImage src="./gw.jpg" alt="GW säger hej" />
          <HeroContent>
            <HeroContentWrap>
              <HeroContentInner>
                <HeroTitle>”…Lite mer som GW”</HeroTitle>
              </HeroContentInner>
            </HeroContentWrap>
          </HeroContent>
        </Hero>

        {loading && <div>Loading...</div>}

        {!loading && (
          <ListWrap>
            <ListHeading>Svängigast just nu</ListHeading>
            <List>
              {songs.map((item: any, index: number) => {
                const { title, youtubeId, startTime } = item;

                const linkTo = `/play/${youtubeId}${startTime ? `?start=${startTime}` : ''}`;

                return (
                  <ListItem key={`listItem_${index}`}>
                    <SongLink to={linkTo}>
                      <div>
                        <Artist>{title.split(' - ')[0]}</Artist>
                        <Track>{title.split(' - ')[1]}</Track>
                      </div>
                    </SongLink>
                  </ListItem>
                );
              })}
            </List>
          </ListWrap>
        )}
      </Main>
    );
  }
}

const ListWrap = styled.div`
  width: 836px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -14px;
  width: calc(100% + 28px);
  box-sizing: border-box;
  list-style: none;
`;

const ListHeading = styled.h2`
  font-family: Helvetica;
  ¨font-weight: bold;
  font-size: 20px;
  color: #51596d;
  margin: 40px 0 25px;
`;

const Artist = styled.span`
  display: block;
  font-weight: bold;
  color: #1c1e25;
  font-size: 14px;
  line-height: 24px;
`;

const Track = styled.span`
  display: block;
  color: #51596d;
  font-size: 14px;
  line-height: 16px;
`;

const ListItem = styled.li`
  flex: 0 0 calc(50% - 28px);
  max-width: calc(50% - 28px);
  background-color: white;
  border-radius: 8px;
  margin: 0 14px 8px;
  box-sizing: border-box;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  }
`;

const SongLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
  padding: 6px 16px;
  height: 60px;
`;

const Line = styled.div`
  background-image: linear-gradient(to right, #7cff00, #ff004c, #000dff);
  height: 10px;
  width: 100%;
`;

const Hero = styled.section`
  display: flex;
  width: 100%;
`;

const HeroImage = styled.img`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  background: red;
  pointer-events: none;
  object-fit: cover;
`;

const HeroContentWrap = styled.div`
  width: 836px;
  margin: 0 auto;
`;

const HeroContent = styled.div`
  flex: 0 0 100%;
  position: relative;
  transform: translateX(-100%);
  background-image: linear-gradient(135deg, rgba(124, 255, 0, 0.48) 0%, rgba(0, 13, 255, 0.76) 100%);
  z-index: 2;
`;

const HeroContentInner = styled.div`
  padding: 9.47265625vw 5.17578125vw;

  @media (min-width: 1024px) {
    padding: 97px 0;
  }
`;

const HeroTitle = styled.h1`
  font-family: Helvetica, sans-serif;
  font-size: 7.8125vw;
  color: #ffffff;
  letter-spacing: -0.1953125vw;
  max-width: 43.84765625vw;
  font-weight: bold;
  margin: 0;

  @media (min-width: 1024px) {
    max-width: 449px;
    font-size: 80px;
    letter-spacing: -2px;
  }
`;

const Main = styled.main`
  background-color: #fafbfe;
  min-height: 100vh;
  padding-bottom: 64px;
`;

export default Home;
