export default class ResponseError extends Error {
  public httpStatus: number;

  constructor(httpStatus: number, ...params: any) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }

    this.name = 'ResponseError';
    // Custom debugging information
    this.httpStatus = httpStatus;
  }
}
