import ThemeInterface from 'src/interfaces/theme-interface';
import { ThemedCssFunction } from 'styled-components';
import { css } from 'styled-components';

const sizes = {
  small: 475,
  // tslint:disable-next-line: object-literal-sort-keys
  medium: 768,
  large: 1024,
  xlarge: 1440,
};

const mq = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;

    return acc;
  },
  {} as { [key in keyof typeof sizes]: ThemedCssFunction<ThemeInterface> }
);

export default mq;
